import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import React from 'react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { AnimateSharedLayout } from 'framer-motion';
import Head from 'next/head';

function MyApp({ Component, pageProps }: AppProps) {
  const gtmParams = {
    id: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
    environment: {
      gtm_auth: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV_AUTH,
      gtm_preview: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV_PREVIEW,
    },
  };

  const siteTitle = 'UNSW - Inside Out';
  const siteDecription =
    'How do your values shape your future? With Inside Out, discover how your values match with different degrees at UNSW and map your future career!';
  const host = process.env.NEXT_PUBLIC_VERCEL_URL
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : 'http://localhost:3000';
  const previewImage = `${host}/preview.png`;
  return (
    <AnimateSharedLayout>
      <GTMProvider state={gtmParams}>
        <Head>
          <title>{siteTitle}</title>
          <meta name="title" content={siteTitle} />
          <meta property="og:title" content={siteTitle} />
          <meta property="twitter:title" content={siteTitle} />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <link rel="icon" sizes="16x16 32x32 64x64" href="/favicon.ico" />
          <link rel="icon" type="image/png" sizes="196x196" href="/favicon-192.png" />
          <link rel="icon" type="image/png" sizes="160x160" href="/favicon-160.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96.png" />
          <link rel="icon" type="image/png" sizes="64x64" href="/favicon-64.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16.png" />
          <link rel="apple-touch-icon" href="/favicon-57.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/favicon-114.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/favicon-72.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/favicon-144.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/favicon-60.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/favicon-120.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/favicon-76.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/favicon-152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/favicon-180.png" />
          <meta name="msapplication-TileColor" content="#FFFFFF" />
          <meta name="msapplication-TileImage" content="/favicon-144.png" />
          <meta name="msapplication-config" content="/browserconfig.xml" />
          <meta name="description" content={siteDecription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={host} />
          <meta property="og:description" content={siteDecription} />
          <meta property="og:image" content={previewImage} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={host} />
          <meta property="twitter:description" content={siteDecription} />
          <meta property="twitter:image" content={previewImage} />
        </Head>
        <Component {...pageProps} />
      </GTMProvider>
    </AnimateSharedLayout>
  );
}

export default MyApp;
